import React from 'react';
import styled from 'styled-components';
import Headline from '@components/atoms/Headline/Headline';

const StyledWrapper = styled.div`
  height: 500px;
  width: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PrivacyHero = () => {
  return (
    <StyledWrapper>
      <Headline>Polityka prywatności</Headline>
    </StyledWrapper>
  );
};

export default PrivacyHero;
