import React from 'react';
import styled from 'styled-components';
import { BasicTemplate } from '@templates/BasicTemplate';
import PrivacyHero from '@components/molecules/PrivacyHero/PrivacyHero';

const PageContainer = styled.div`
  width: 90%;
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45px;
  font-family: ${({ theme }) => theme.primaryFont};

  @media (min-width: 1025px) {
    max-width: 1250px;
  }
`;

const PrivacyPolicyPage = () => {
  return (
    <BasicTemplate header={PrivacyHero} title="Polityka prywatności">
      <PageContainer>
        <h2>
          Polityka prywatności opisuje zasady przetwarzania przez nas informacji
          na Tw&oacute;j temat, w tym danych osobowych oraz ciasteczek, czyli
          tzw. cookies.
        </h2>
        <hr />
        <div className="lh166">
          <h2 className="pb-12">
            <strong>1. Informacje og&oacute;lne</strong>
          </h2>
          <ol>
            <li>
              Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod
              adresem url:<span>&nbsp;</span>
              <b>
                diamentowezacisze.pl i inne subdomeny np.
                rzaska.diamentowezacisze.pl
              </b>
            </li>
            <li>
              Operatorem serwisu oraz Administratorem danych osobowych jest:
              Marys sp. zo o, ul.&nbsp; Słoneczna 16, 32-083 Balice Szczyglice
            </li>
            <li>
              Adres kontaktowy poczty elektronicznej operatora:
              info@diamentowezacisze.pl
            </li>
            <li>
              Operator jest Administratorem Twoich danych osobowych w
              odniesieniu do danych podanych dobrowolnie w Serwisie.
            </li>
            <li>Serwis wykorzystuje dane osobowe w następujących celach:</li>
            <ul>
              <li>Prowadzenie rozm&oacute;w typu chat online</li>
              <li>Obsługa zapytań przez formularz</li>
              <li>Prezentacja oferty lub informacji</li>
            </ul>
            <li>
              Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i
              ich zachowaniu w następujący spos&oacute;b:
              <ol>
                <li>
                  Poprzez dobrowolnie wprowadzone w formularzach dane,
                  kt&oacute;re zostają wprowadzone do system&oacute;w Operatora.
                </li>
                <li>
                  Poprzez zapisywanie w urządzeniach końcowych plik&oacute;w
                  cookie (tzw. &bdquo;ciasteczka&rdquo;).
                </li>
              </ol>
            </li>
          </ol>
          <h2 className="pb-12">
            <strong>
              2. Wybrane metody ochrony danych stosowane przez Operatora
            </strong>
          </h2>
          <ol>
            <li>
              Miejsca logowania i wprowadzania danych osobowych są chronione w
              warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i
              dane logowania, wprowadzone na stronie, zostają zaszyfrowane w
              komputerze użytkownika i mogą być odczytane jedynie na docelowym
              serwerze.
            </li>
          </ol>
          <h2 className="pb-12">
            <strong>3. Hosting</strong>
          </h2>
          <ol>
            <li>
              Serwis jest hostowany (technicznie utrzymywany) na serwerach
              operatora: cyberFolks.pl
            </li>
          </ol>
          <h2 className="pb-12">
            <strong>
              4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania
              danych
            </strong>
          </h2>
          <ol>
            <li>
              W niekt&oacute;rych sytuacjach Administrator ma prawo przekazywać
              Twoje dane osobowe innym odbiorcom, jeśli będzie to niezbędne do
              wykonania zawartej z Tobą umowy lub do zrealizowania
              obowiązk&oacute;w ciążących na Administratorze. Dotyczy to takich
              grup odbiorc&oacute;w:
              <ul>
                <li>firma hostingowa na zasadzie powierzenia</li>
                <li>operatorzy pocztowi</li>
                <li>operatorzy rozwiązania typu chat online</li>
                <li>
                  upoważnieni pracownicy i wsp&oacute;łpracownicy, kt&oacute;rzy
                  korzystają z danych w celu realizacji celu działania strony
                </li>
                <li>
                  firmy, świadczące usługi marketingu na rzecz Administratora
                </li>
              </ul>
            </li>
            <li>
              Twoje dane osobowe przetwarzane przez Administratora nie dłużej,
              niż jest to konieczne do wykonania związanych z nimi czynności
              określonych osobnymi przepisami (np. o prowadzeniu rachunkowości).
              W odniesieniu do danych marketingowych dane nie będą przetwarzane
              dłużej niż przez 3 lata.
            </li>
            <li>
              Przysługuje Ci prawo żądania od Administratora:
              <ul>
                <li>dostępu do danych osobowych Ciebie dotyczących,</li>
                <li>ich sprostowania,</li>
                <li>usunięcia,</li>
                <li>ograniczenia przetwarzania,</li>
                <li>oraz przenoszenia danych.</li>
              </ul>
            </li>
            <li>
              Przysługuje Ci prawo do złożenia sprzeciwu w zakresie
              przetwarzania wskazanego w pkt 3.3 c) wobec przetwarzania danych
              osobowych w celu wykonania prawnie uzasadnionych interes&oacute;w
              realizowanych przez Administratora, w tym profilowania, przy czym
              prawo sprzeciwu nie będzie mogło być wykonane w przypadku
              istnienia ważnych prawnie uzasadnionych podstaw do przetwarzania,
              nadrzędnych wobec Ciebie interes&oacute;w, praw i wolności, w
              szczeg&oacute;lności ustalenia, dochodzenia lub obrony roszczeń.
            </li>
            <li>
              Na działania Administratora przysługuje skarga do Prezesa Urzędu
              Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.
            </li>
            <li>
              Podanie danych osobowych jest dobrowolne, lecz niezbędne do
              obsługi Serwisu.
            </li>
            <li>
              W stosunku do Ciebie mogą być podejmowane czynności polegające na
              zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu
              świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia
              przez Administratora marketingu bezpośredniego.
            </li>
            <li>
              Dane osobowe nie są przekazywane od kraj&oacute;w trzecich w
              rozumieniu przepis&oacute;w o ochronie danych osobowych. Oznacza
              to, że nie przesyłamy ich poza teren Unii Europejskiej.
            </li>
          </ol>
          <h2 className="pb-12">
            <strong>5. Informacje w formularzach</strong>
          </h2>
          <ol>
            <li>
              Serwis zbiera informacje podane dobrowolnie przez użytkownika, w
              tym dane osobowe, o ile zostaną one podane.
            </li>
            <li>
              Serwis może zapisać informacje o parametrach połączenia
              (oznaczenie czasu, adres IP).
            </li>
            <li>
              Serwis, w niekt&oacute;rych wypadkach, może zapisać informację
              ułatwiającą powiązanie danych w formularzu z adresem e-mail
              użytkownika wypełniającego formularz. W takim wypadku adres e-mail
              użytkownika pojawia się wewnątrz adresu url strony zawierającej
              formularz.
            </li>
            <li>
              Dane podane w formularzu są przetwarzane w celu wynikającym z
              funkcji konkretnego formularza, np. w celu dokonania procesu
              obsługi zgłoszenia serwisowego lub kontaktu handlowego,
              rejestracji usług itp. Każdorazowo kontekst i opis formularza w
              czytelny spos&oacute;b informuje, do czego on służy.
            </li>
          </ol>
          <h2 className="pb-12">
            <strong>6. Logi Administratora</strong>
          </h2>
          <ol>
            <li>
              Informacje zachowaniu użytkownik&oacute;w w serwisie mogą podlegać
              logowaniu. Dane te są wykorzystywane w celu administrowania
              serwisem.
            </li>
          </ol>
          <h2 className="pb-12">
            <strong>7. Istotne techniki marketingowe</strong>
          </h2>
          <ol>
            <li>
              Operator stosuje analizę statystyczną ruchu na stronie, poprzez
              Google Analytics (Google Inc. z siedzibą w USA). Operator nie
              przekazuje do operatora tej usługi danych osobowych, a jedynie
              zanonimizowane informacje. Usługa bazuje na wykorzystaniu
              ciasteczek w urządzeniu końcowym użytkownika. W zakresie
              informacji o preferencjach użytkownika gromadzonych przez sieć
              reklamową Google użytkownik może przeglądać i edytować informacje
              wynikające z plik&oacute;w cookies przy pomocy narzędzia:
              https://www.google.com/ads/preferences/
            </li>
            <li>
              Operator stosuje techniki remarketingowe, pozwalające na
              dopasowanie przekaz&oacute;w reklamowych do zachowania użytkownika
              na stronie, co może dawać złudzenie, że dane osobowe użytkownika
              są wykorzystywane do jego śledzenia, jednak w praktyce nie
              dochodzi do przekazania żadnych danych osobowych od Operatora do
              operatorom reklam. Technologicznym warunkiem takich działań jest
              włączona obsługa plik&oacute;w cookie.
            </li>
            <li>
              Operator stosuje korzysta z piksela Facebooka. Ta technologia
              powoduje, że serwis Facebook (Facebook Inc. z siedzibą w USA) wie,
              że dana osoba w nim zarejestrowana korzysta z Serwisu. Bazuje w
              tym wypadku na danych, wobec kt&oacute;rych sam jest
              administratorem, Operator nie przekazuje od siebie żadnych
              dodatkowych danych osobowych serwisowi Facebook. Usługa bazuje na
              wykorzystaniu ciasteczek w urządzeniu końcowym użytkownika.
            </li>
            <li>
              Operator stosuje rozwiązanie badające zachowanie
              użytkownik&oacute;w poprzez tworzenie map ciepła oraz nagrywanie
              zachowania na stronie. Te informacje są anonimizowane zanim
              zostaną przesłane do operatora usługi tak, że nie wie on jakiej
              osoby fizycznej one dotyczą. W szczeg&oacute;lności nagrywaniu nie
              podlegają wpisywane hasła oraz inne dane osobowe.
            </li>
            <li>
              Operator stosuje rozwiązanie automatyzujące działanie Serwisu w
              odniesieniu do użytkownik&oacute;w, np. mogące przesłać maila do
              użytkownika po odwiedzeniu konkretnej podstrony, o ile wyraził on
              zgodę na otrzymywanie korespondencji handlowej od Operatora.
            </li>
          </ol>
          <h2 className="pb-12">
            <strong>8. Informacja o plikach cookies</strong>
          </h2>
          <ol>
            <li>Serwis korzysta z plik&oacute;w cookies.</li>
            <li>
              Pliki cookies (tzw. &bdquo;ciasteczka&rdquo;) stanowią dane
              informatyczne, w szczeg&oacute;lności pliki tekstowe, kt&oacute;re
              przechowywane są w urządzeniu końcowym Użytkownika Serwisu i
              przeznaczone są do korzystania ze stron internetowych Serwisu.
              Cookies zazwyczaj zawierają nazwę strony internetowej, z
              kt&oacute;rej pochodzą, czas przechowywania ich na urządzeniu
              końcowym oraz unikalny numer.
            </li>
            <li>
              Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika
              Serwisu pliki cookies oraz uzyskującym do nich dostęp jest
              operator Serwisu.
            </li>
            <li>
              Pliki cookies wykorzystywane są w następujących celach:
              <ol>
                <li>
                  utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki
                  kt&oacute;rej użytkownik nie musi na każdej podstronie Serwisu
                  ponownie wpisywać loginu i hasła;
                </li>
                <li>
                  realizacji cel&oacute;w określonych powyżej w części "Istotne
                  techniki marketingowe";
                </li>
              </ol>
            </li>
            <li>
              W ramach Serwisu stosowane są dwa zasadnicze rodzaje plik&oacute;w
              cookies: &bdquo;sesyjne&rdquo; (session cookies) oraz
              &bdquo;stałe&rdquo; (persistent cookies). Cookies
              &bdquo;sesyjne&rdquo; są plikami tymczasowymi, kt&oacute;re
              przechowywane są w urządzeniu końcowym Użytkownika do czasu
              wylogowania, opuszczenia strony internetowej lub wyłączenia
              oprogramowania (przeglądarki internetowej). &bdquo;Stałe&rdquo;
              pliki cookies przechowywane są w urządzeniu końcowym Użytkownika
              przez czas określony w parametrach plik&oacute;w cookies lub do
              czasu ich usunięcia przez Użytkownika.
            </li>
            <li>
              Oprogramowanie do przeglądania stron internetowych (przeglądarka
              internetowa) zazwyczaj domyślnie dopuszcza przechowywanie
              plik&oacute;w cookies w urządzeniu końcowym Użytkownika.
              Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym
              zakresie.&nbsp;Przeglądarka internetowa umożliwia usunięcie
              plik&oacute;w cookies. Możliwe jest także automatyczne blokowanie
              plik&oacute;w cookies Szczeg&oacute;łowe informacje na ten temat
              zawiera pomoc lub dokumentacja przeglądarki internetowej.
            </li>
            <li>
              Ograniczenia stosowania plik&oacute;w cookies mogą wpłynąć na
              niekt&oacute;re funkcjonalności dostępne na stronach internetowych
              Serwisu.
            </li>
            <li>
              Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
              Serwisu wykorzystywane mogą być r&oacute;wnież przez
              wsp&oacute;łpracujące z operatorem Serwisu podmioty, w
              szczeg&oacute;lności dotyczy to firm: Google (Google Inc. z
              siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA),
              Twitter (Twitter Inc. z siedzibą w USA).
            </li>
          </ol>
          <h2 className="pb-12">
            <strong>
              9. Zarządzanie plikami cookies &ndash; jak w praktyce wyrażać i
              cofać zgodę?
            </strong>
          </h2>
          <ol>
            <li>
              Jeśli użytkownik nie chce otrzymywać plik&oacute;w cookies, może
              zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie
              obsługi plik&oacute;w cookies niezbędnych dla proces&oacute;w
              uwierzytelniania, bezpieczeństwa, utrzymania preferencji
              użytkownika może utrudnić,&nbsp;a w skrajnych przypadkach może
              uniemożliwić korzystanie ze stron www
            </li>
            <li>
              W celu zarządzania ustawienia cookies wybierz z listy poniżej
              przeglądarkę internetową, kt&oacute;rej używasz i postępuj zgodnie
              z instrukcjami:
              <ul>
                <li>
                  <a href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history">
                    Edge
                  </a>
                </li>
                <li>
                  <a href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer">
                    Internet Explorer
                  </a>
                </li>
                <li>
                  <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">
                    Chrome
                  </a>
                </li>
                <li>
                  <a href="http://support.apple.com/kb/PH5042">Safari</a>
                </li>
                <li>
                  <a href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">
                    Firefox
                  </a>
                </li>
                <li>
                  <a href="http://help.opera.com/Windows/12.10/pl/cookies.html">
                    Opera
                  </a>
                </li>
              </ul>
              <p>Urządzenia mobilne:</p>
              <ul>
                <li>
                  <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">
                    Android
                  </a>
                </li>
                <li>
                  <a href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL">
                    Safari (iOS)
                  </a>
                </li>
                <li>
                  <a href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings">
                    Windows Phone
                  </a>
                </li>
              </ul>
            </li>
          </ol>
          <div className="disclaimer pt-36">
            <p>
              Niniejszy wz&oacute;r polityki został wygenerowany bezpłatnie, w
              celach informacyjnych, w oparciu o naszą wiedzę, branżowe praktyki
              i przepisy prawa obowiązujące na dzień 2018-08-14. Zalecamy
              sprawdzenie wzoru polityki przed użyciem jej na stronie.
              Wz&oacute;r opiera się na najczęściej występujących na stronach
              internetowych sytuacjach, ale może nie odzwierciedlać pełnej i
              dokładnej specyfiki Twojej strony www. Przeczytaj uważnie
              wygenerowany dokument i w razie potrzeb dostosuj go do Twojej
              sytuacji lub zasięgnij porady prawnej. Nie bierzemy
              odpowiedzialności za skutki posługiwania się tym dokumentem,
              ponieważ tylko Ty masz wpłw na to, czy wszystkie informacje w nim
              zawarte są zgodne z prawdą. Zwr&oacute;ć także uwagę, że Polityka
              Prywatności, nawet najlepsza, jest tylko jednym z element&oacute;w
              Twojej troski o dane osobowe i prywatność użytkownika na stronie
              www.
            </p>
          </div>
        </div>
      </PageContainer>
    </BasicTemplate>
  );
};

export default PrivacyPolicyPage;
